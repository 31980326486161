<template>
  <transition name="view-fade">
    <div v-show="fullScreenLoaderVisible" :style="`top:${top}px;`" class="full-screen-loader">
      <div class="wrapper">
        <div class="ball orange" />
        <div class="ball cyan" />
        <div class="ball blue" />
        <div class="ball yellow" />
      </div>
      <div class="loader-title">
        {{ title }}
      </div>
    </div>
  </transition>
</template>

<i18n>
{
  "en": {
    "Loading": "Loading"
  },
  "ja": {
    "Loading": "読み込み"
  }
}
</i18n>

<script>
import { mapState } from 'vuex';

export default {
  name: 'FullScreenLoader',
  data() {
    return {
      top: 0,
      excludeElements: ['.app-header', '.hero-my-bookings'],
    };
  },
  computed: {
    ...mapState(['fullScreenLoaderVisible', 'fullScreenLoaderTitle']),
    title() {
      return this.fullScreenLoaderTitle ? this.fullScreenLoaderTitle : this.$t('Loading');
    },
  },
  watch: {
    fullScreenLoaderVisible: {
      immediate: true,
      handler(nV) {
        if (nV) {
          document.querySelector('body').classList.add('full-screen-loader-visible');
        } else {
          document.querySelector('body').classList.remove('full-screen-loader-visible');
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
$_jh_fullscreen-z-index: 2000;
.full-screen-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: $_jh_fullscreen-z-index + 1000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.75);
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.ball {
  width: 10px;
  height: 10px;
  border-radius: 6px;
  margin: 0 6px;

  animation: 1.5s bounce ease infinite;
}

.orange {
  background-color: #009500;
}

.cyan {
  background-color: #2ac72a;
  animation-delay: 0.125s;
}

.blue {
  background-color: #009500;
  animation-delay: 0.25s;
}

.yellow {
  background-color: #2ac72a;
  animation-delay: 0.5s;
}

.loader-title {
  color: $primary;
  font-weight: bold;
  text-transform: uppercase;
  font-size: $size-6;
  margin-top: 10px;
}

@keyframes bounce {
  50% {
    transform: translateY(25px);
  }
}
</style>

<style lang="scss">
body.full-screen-loader-visible {
  overflow: hidden;

  .app-header {
    position: relative;
    z-index: 3002;
  }
}
</style>
