<template>
  <a-form class="columns is-mini-gap is-multiline">
    <div class="column is-12">
      <h3 class="has-text-black has-text-weight-bold is-size-4">
        {{ $t('title') }}
      </h3>
    </div>
    <a-form-item class="column is-12">
      <a-radio-group v-model:value="locale">
        <a-radio :style="radioStyle" value="en">
          {{ $t('English') }}
        </a-radio>
        <a-radio :style="radioStyle" value="ja">
          {{ $t('Japanese') }}
        </a-radio>
      </a-radio-group>
    </a-form-item>
    <div class="column has-text-right is-12 m-t-4x">
      <a-button
        class="button-secondary-outline"
        style="min-width: 115px"
        :size="size"
        @click="handleCancel"
      >
        {{ $t('Cancel') }}
      </a-button>
    </div>
  </a-form>
</template>

<i18n>
{
'en':{
'title': 'Select Language',
'Cancel': 'Cancel',
'English': 'English',
'Japanese': 'Japanese'
},
ja:{
'title': '選択言語',
'Cancel': '取り消し',
'English': '英語',
'Japanese': '日本語'
}
}
</i18n>

<script>
import { setLocale } from '@/utils/locale';

export default {
  name: 'LanguageSwitcher',
  props: {
    size: {
      type: String,
      default: 'large',
    },
  },
  emits: ['cancel'],
  data() {
    return {
      radioStyle: {
        display: 'block',
        height: '30px',
        lineHeight: '30px',
      },
    };
  },
  computed: {
    locale: {
      get() {
        return this.$i18n.locale;
      },
      set(locale) {
        this.$i18n.locale = locale;
        setLocale(locale);
        window.location.reload();
      },
    },
  },
  methods: {
    handleCancel() {
      this.$emit('cancel', false);
    },
  },
};
</script>

<style scoped></style>
