<template>
  <span>
    <img class="main-logo" src="@/assets/images/srs-logo.svg" />
  </span>
</template>

<style lang="scss" scoped>
.main-logo {
  height: 50px !important;
  width: auto;
  // max height as the nav bar set the varibies
  max-height: $navbar-height;
  vertical-align: middle;
}

/* .NuxtLogo {
  animation: 1s appear;
  margin: auto;
}

@keyframes appear {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
} */
</style>
