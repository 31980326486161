<template>
  <span>
    <a-modal
      v-model:visible="visible"
      :footer="null"
      :closable="closable"
      :mask-closable="maskClosable"
      destroy-on-close
      :mask="mask"
    >
      <slot :hide="hide" />
    </a-modal>
    <slot name="handler" :show="show" />
  </span>
</template>

<script>
export default {
  name: 'Modal',
  props: {
    closable: {
      type: Boolean,
    },
    maskClosable: {
      type: Boolean,
    },
    mask: {
      type: Boolean,
      // eslint-disable-next-line vue/no-boolean-default
      default: true,
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
  },
};
</script>

<style scoped></style>
