<template>
  <span class="navi-container">
    <a
      aria-expanded="false"
      aria-label="menu"
      class="custom-navbar-burger"
      role="button"
      @click="showDrawer"
    >
      <svg
        width="24"
        height="18"
        viewBox="0 0 24 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M0 0H24V3H0V0ZM0 7.5H24V10.5H0V7.5ZM0 15H24V18H0V15Z" fill="currentColor" />
      </svg>
    </a>
    <a-drawer
      :width="width"
      wrap-class-name="navi-drawer"
      title=" "
      :placement="placement"
      :closable="false"
      :visible="visible"
      @close="onClose"
    >
      <ul class="navi-menu">
        <li v-permission="['view-alert', 'add-alert', 'modify-alert']">
          <router-link active-class="is-active" to="/alerts">
            <img src="../assets/images/icon-alert.svg" width="18" height="18" alt="alert">
            <strong>{{ $t('Alert') }}</strong>
          </router-link>
        </li>
        <li v-permission="adminMenuPermission">
          <span :class="{ isActive: adminOpened }" @click="adminOpened = !adminOpened">
            <img src="../assets/images/icon-admin.svg" width="18" height="18" alt="admin">
            <strong>{{ $t('Admin') }}</strong>
          </span>
          <transition name="view-fade">
            <ul v-show="adminOpened">
              <li v-permission="['view-user', 'add-user', 'modify-user']">
                <router-link active-class="is-active" to="/users">
                  {{ $t('Users') }}
                </router-link>
              </li>
              <li v-permission="['view-partner', 'add-partner', 'modify-partner']">
                <router-link active-class="is-active" to="/partners">
                  {{ $t('Partners') }}
                </router-link>
              </li>
              <li v-permission="['view-payment', 'add-payment', 'modify-payment']">
                <router-link active-class="is-active" to="/payments">
                  {{ $t('Payments') }}
                </router-link>
              </li>
              <li v-permission="['view-system-log']">
                <router-link active-class="is-active" to="/system-Logs">
                  {{ $t('System Logs') }}
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
        <li
          v-if="!hideSimulationPage && $mq !== 'mobile'"
          v-permission="['simulate']"
        >
          <span
            :class="{ isActive: simulationOpened }"
            @click="simulationOpened = !simulationOpened"
          >
            <img
              src="../assets/images/icon-simulation.svg"
              width="18"
              height="18"
              alt="Simulations"
            >
            <strong>{{ $t('Simulations') }}</strong>
          </span>
          <transition name="view-fade">
            <ul v-show="simulationOpened">
              <li>
                <router-link
                  active-class="is-active"
                  to="/simulations/search"
                >
                  {{ $t('Simulations') }}
                </router-link>
              </li>
              <li>
                <router-link
                  active-class="is-active"
                  to="/simulations"
                >
                  {{ $t('Simulation Calculation List') }}
                </router-link>
              </li>
              <li>
                <router-link
                  active-class="is-active"
                  to="/simulations/calculation-adjustment"
                >
                  {{ $t('Calculation Adjustment') }}
                </router-link>
              </li>
              <li>
                <router-link
                  active-class="is-active"
                  to="/simulations/general-addons"
                >
                  {{ $t('General Add-ons List') }}
                </router-link>
              </li>
              <li>
                <router-link
                  active-class="is-active"
                  to="/simulations/room-addons"
                >
                  {{ $t('Room Add-ons List') }}
                </router-link>
              </li>
              <li>
                <router-link
                  active-class="is-active"
                  to="/simulations/prefecture-occ"
                >
                  {{ $t('Prefecture OCC List') }}
                </router-link>
              </li>
              <li>
                <router-link
                  active-class="is-active"
                  to="/simulations/room-sizes"
                >
                  {{ $t('Room Size List') }}
                </router-link>
              </li>
              <li>
                <router-link
                  active-class="is-active"
                  to="/simulations/reservation-meals"
                >
                  {{ $t('ND401000 List') }}
                </router-link>
              </li>
              <li>
                <router-link
                  active-class="is-active"
                  to="/simulations/extractions"
                >
                  {{ $t('Data Extraction') }}
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
        <li v-if="isAdmin && enableNeppanSimulator">
          <router-link active-class="is-active" to="/neppan/reservations">
            <img
              src="../assets/images/icon-reservation-simulator.svg"
              width="18"
              height="18"
              alt="Simulations"
            >
            <strong>{{ $t('Neppan Simulator') }}</strong>
          </router-link>
        </li>
        <li
          v-permission="operationsMenuPermission"
          v-role="operationsMenuRoles"
        >
          <span
            :class="{ isActive: operationsOpened }"
            @click="operationsOpened = !operationsOpened"
          >
            <img src="../assets/images/icon-operations.svg" width="18" height="18" alt="admin">
            <strong>{{ $t('Operations') }}</strong>
          </span>
          <transition name="view-fade">
            <ul v-show="operationsOpened">
              <li v-permission="['view-property', 'add-property', 'modify-property']">
                <router-link active-class="is-active" to="/properties">
                  {{ $t('Properties') }}
                </router-link>
              </li>
              <li v-permission="['add-working-date', 'modify-working-date']">
                <router-link active-class="is-active" to="/working-date">
                  {{ $t('Working Date') }}
                </router-link>
              </li>
              <li v-permission="['view-hc-task']">
                <router-link active-class="is-active" to="/calendar">
                  {{ $t('Housekeeping Assignment Calendar') }}
                </router-link>
              </li>
              <li v-permission="['view-reservation', 'add-reservation', 'modify-reservation']">
                <router-link active-class="is-active" to="/reservations/calendar">
                  {{ $t('Reservations Calendar') }}
                </router-link>
              </li>
              <li v-permission="['view-reservation', 'add-reservation', 'modify-reservation']">
                <router-link active-class="is-active" to="/reservations">
                  {{ $t('Reservations') }}
                </router-link>
              </li>
              <li v-permission="['view-inspection', 'add-inspection', 'modify-inspection']">
                <router-link active-class="is-active" to="/inspections">
                  {{ $t('Inspection List') }}
                </router-link>
              </li>
              <li
                v-permission="[
                  'view-room-assignment',
                  'add-room-assignment',
                  'modify-room-assignment',
                ]"
              >
                <router-link active-class="is-active" to="/room-assignment">
                  {{ $t('Room Assignment') }}
                </router-link>
              </li>
              <li
                v-permission="[
                  'view-room-assignment',
                  'add-room-assignment',
                  'modify-room-assignment',
                ]"
              >
                <router-link active-class="is-active" to="/assignment-request">
                  {{ $t('Assignment Request') }}
                </router-link>
              </li>
              <li
                v-permission="[
                  'view-expense',
                  'add-expense',
                  'modify-expense',
                  'request-expence-approval',
                ]"
                v-role="['HC', 'HKP']"
              >
                <router-link active-class="is-active" to="/expenses">
                  {{ $t('Expenses') }}
                </router-link>
              </li>
              <li v-permission="['view-hc-task', 'modify-hc-task']">
                <router-link active-class="is-active" to="/housekeeper-tasks">
                  {{ $t('Housekeeper Task List') }}
                </router-link>
              </li>
              <li
                v-permission="['view-stock-control', 'add-stock-control', 'modify-stock-control']"
              >
                <router-link active-class="is-active" to="/stocks">
                  {{ $t('Stock Control') }}
                </router-link>
              </li>
              <li
                v-permission="[
                  'view-lost-and-found',
                  'add-lost-and-found',
                  'modify-lost-and-found',
                ]"
              >
                <router-link active-class="is-active" to="/lost-founds">
                  {{ $t('Lost & Found') }}
                </router-link>
              </li>
              <li v-permission="['view-out-of-order', 'add-out-of-order', 'modify-out-of-order']">
                <router-link active-class="is-active" to="/out-of-order">
                  {{ $t('Out of Order') }}
                </router-link>
              </li>
              <li v-permission="['view-incident', 'add-incident', 'modify-incident']">
                <router-link active-class="is-active" to="/incidents">
                  {{ $t('Incident') }}
                </router-link>
              </li>
              <li
                v-permission="[
                  'view-guest-response',
                  'add-guest-response',
                  'modify-guest-response',
                ]"
              >
                <router-link active-class="is-active" to="/guest-response">
                  {{ $t('Guest Response') }}
                </router-link>
              </li>
              <li
                v-permission="[
                  'view-guest-email'
                ]"
              >
                <router-link active-class="is-active" to="/guest-email">
                  {{ $t('Guest Email') }}
                </router-link>
              </li>
              <li>
                <router-link active-class="is-active" to="/upsell-items">
                  {{ $t('Upsell Items') }}
                </router-link>
              </li>
              <li>
                <router-link active-class="is-active" to="/upsell-item-orders">
                  {{ $t('Upsell Item Orders') }}
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
        <li v-permission="['view-report']">
          <span :class="{ isActive: reportOpened }" @click="reportOpened = !reportOpened">
            <img src="../assets/images/icon-report.svg" width="18" height="18" alt="report">
            <strong>{{ $t('Reports') }}</strong>
          </span>
          <transition name="view-fade">
            <ul v-show="reportOpened">
              <li v-permission="['view-report']">
                <router-link active-class="is-active" to="/reports/housekeeping-companies">
                  {{ $t('Housekeeping Company') }}
                </router-link>
              </li>
              <li v-permission="['view-report']">
                <router-link active-class="is-active" to="/reports/lost-founds">
                  {{ $t('Lost & Found') }}
                </router-link>
              </li>
              <li v-permission="['view-report']">
                <router-link active-class="is-active" to="/reports/out-of-order">
                  {{ $t('Out of Order') }}
                </router-link>
              </li>
              <li v-permission="['view-report']">
                <router-link active-class="is-active" to="/reports/stocks">
                  {{ $t('Stock Control') }}
                </router-link>
              </li>
              <li v-permission="['view-report']">
                <router-link active-class="is-active" to="/reports/expenses">
                  {{ $t('Expenses') }}
                </router-link>
              </li>
              <li v-permission="['view-report']">
                <router-link active-class="is-active" to="/reports/reservation-counter">
                  {{ $t('Reservation counter') }}
                </router-link>
              </li>
              <li v-permission="['view-report']">
                <router-link active-class="is-active" to="/reports/download">
                  {{ $t('Download') }}
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
        <li>
          <span :class="{ isActive: iscmOpened }" @click="iscmOpened = !iscmOpened">
            <img src="../assets/images/icon-admin.svg" width="18" height="18" alt="admin">
            <strong>{{ $t('Innovative SCM') }}</strong>
          </span>
          <transition name="view-fade">
            <ul v-show="iscmOpened">
              <li v-permission="['view-rate']">
                <router-link active-class="is-active" to="/iscm/auto-reflection-settings">
                  {{ $t('Auto Reflection Settings') }}
                </router-link>
              </li>
              <li v-permission="['view-rate']">
                <router-link active-class="is-active" to="/iscm/rates/per-room">
                  {{ $t('Rates Per Room') }}
                </router-link>
              </li>
              <li v-permission="['view-rate']">
                <router-link active-class="is-active" to="/iscm/rates/per-person">
                  {{ $t('Rates Per Person') }}
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
        <li v-permission="rmsPermissions">
          <span :class="{ isActive: rmsOpened }" @click="rmsOpened = !rmsOpened">
            <img src="../assets/images/icon-admin.svg" width="18" height="18" alt="admin">
            <strong>{{ $t('Revenue Management') }}</strong>
          </span>
          <transition name="view-fade">
            <ul v-show="rmsOpened">
              <li v-permission="['view-rms']">
                <router-link active-class="is-active" to="/revenue-management/settings">
                  {{ $t('Settings') }}
                </router-link>
              </li>
              <li v-permission="['view-rms']">
                <router-link active-class="is-active" to="/revenue-management/toplines">
                  {{ $t('Toplines') }}
                </router-link>
              </li>
              <li v-permission="['view-rms']" v-role="['ADMIN', 'PROMOTION', 'OPS']">
                <router-link active-class="is-active" to="/revenue-management/topline-checks">
                  {{ $t('Topline Checks') }}
                </router-link>
              </li>
              <li v-permission="['view-room-bottom']">
                <router-link active-class="is-active" to="/revenue-management/room-bottoms">
                  {{ $t('Room Bottom') }}
                </router-link>
              </li>
              <li v-permission="['view-rate']">
                <router-link active-class="is-active" to="/revenue-management/rates/per-room">
                  {{ $t('Rates Per Room') }}
                </router-link>
              </li>
            </ul>
          </transition>
        </li>
        <li>
          <Modal class="language-switcher">
            <template #default="{ hide }">
              <LanguageSwitcher @close="hide" @cancel="hide" />
            </template>
            <template #handler="{ show }">
              <a @click.prevent="show">
                <img
                  src="../assets/images/icon-language.svg"
                  width="18"
                  height="18"
                  alt="Language"
                >
                <strong>{{ $t('Language') }}</strong>
              </a>
            </template>
          </Modal>
        </li>
        <li v-if="isAuthenticated">
          <router-link active-class="is-active" to="/change-password">
            <img
              src="../assets/images/icon-change-password.svg"
              width="18"
              height="18"
              alt="logout"
            >
            <strong>{{ $t('Reset Password') }}</strong>
          </router-link>
        </li>
        <li v-if="isAuthenticated">
          <a @click.prevent="logout">
            <img src="../assets/images/icon-logout.svg" width="18" height="18" alt="logout">
            <strong>{{ $t('Sign Out') }}</strong>
          </a>
        </li>
      </ul>
    </a-drawer>
  </span>
</template>

<i18n>
{
  "en": {
    "Alert": "Alert",
    "Admin": "Admin",
    "Users": "Users",
    "Partners": "Partners",
    "Payments": "Payments",
    "System Logs": "System Logs",
    "Simulations": "Simulations",
    "Operations": "Operations",
    "Properties": "Properties",
    "Housekeeping Assignment Calendar": "Housekeeping Assignment Calendar",
    "Reservations": "Reservations",
    "Inventories": "Inventories",
    "Inspection List": "Inspection List",
    "Room Assignment": "Room Assignment",
    "Assignment Request": "Assignment Request",
    "Housekeeper Task List": "Housekeeper Task List",
    "Stock Control": "Stock Control",
    "Lost & Found": "Lost & Found",
    "Out of Order": "Out of Order",
    "Incident": "Incident",
    "Guest Response": "Guest Response",
    "Language": "Language",
    "Sign Out": "Sign Out",
    "Working Date": "Working Date",
    "Expenses": "Expenses",
    "Reports": "Reports",
    "Housekeeping Company": "Housekeeping Company",
    "Neppan Simulator": "Neppan Simulator",
    "Simulation Calculation List": "Simulation Calculation List",
    "Calculation Adjustment": "Calculation Adjustment",
    "General Add-ons List": "General Add-ons List",
    "Room Add-ons List": "Room Add-ons List",
    "Prefecture OCC List": "Prefecture OCC List",
    "Room Size List": "Room Size List",
    "ND401000 List": "ND401000 List",
    "Reservation counter": "Reservation Counter",
    "Guest Email": "Guest Email",
    "Revenue Management": "Revenue Management",
    "Settings": "Settings",
    "Room Bottom": "Room Bottom",
    "Rates Per Room": "Rates Per Room",
    "Topline Checks": "Topline Checks",
    "Toplines": "Toplines",
    "Data Extraction": "Data Extraction",
    "Reset Password": "Reset Password",
    "Upsell Items": "Upsell Items",
    "Upsell Item Orders": "Upsell Item Orders",
    "Reservations Calendar": "Reservations Calendar",
    "Download": "Download",
  },
  "ja": {
    "Alert": "アラート",
    "Admin": "管理",
    "Users": "ユーザー",
    "Partners": "取引先",
    "Payments": "支払い",
    "System Logs": "システムログ",
    "Simulations": "シミュレーション",
    "Operations": "操作",
    "Properties": "物件",
    "Housekeeping Assignment Calendar": "Housekeeping Assignment Calendar",
    "Reservations": "予約",
    "Inventories": "棚卸資産",
    "Inspection List": "確認箇所リスト",
    "Room Assignment": "新規清掃の登録",
    "Assignment Request": "割り振りをリクエスト",
    "Housekeeper Task List": "清掃担当者タスクリスト",
    "Stock Control": "在庫コントロール",
    "Lost & Found": "忘れ物",
    "Out of Order": "使用不可",
    "Incident": "重大な設備不備",
    "Guest Response": "ゲスト反応",
    "Language": "言語",
    "Sign Out": "ログアウト",
    "Working Date": "勤務日",
    "Expenses": "経費",
    "Reports": "レポート",
    "Housekeeping Company": "清掃会社",
    "Neppan Simulator": "ネパンシミュレーター",
    "Simulation Calculation List": "Simulation Calculation List",
    "Calculation Adjustment": "Calculation Adjustment",
    "General Add-ons List": "General Add-ons List",
    "Room Add-ons List": "Room Add-ons List",
    "Prefecture OCC List": "Prefecture OCC List",
    "Room Size List": "Room Size List",
    "ND401000 List": "ND401000 List",
    "Reservation counter": "Reservation Counter",
    "Guest Email": "自動メール送信",
    "Revenue Management": "レベニューマネージメント",
    "Settings": "設定",
    "Room Bottom": "ボトム料金",
    "Rates Per Room": "料金変更",
    "Topline Checks": "トップライン確認",
    "Toplines": "トップライン",
    "Data Extraction": "Data Extraction",
    "Reset Password": "リセットパスワード",
    "Upsell Items": "Upsell Items",
    "Upsell Item Orders": "注文",
    "Reservations Calendar": "Reservations Calendar",
    "Download": "Download",
  }
}
</i18n>

<script>
import { mapGetters } from 'vuex';
import { logout } from '@/utils/auth';
import { ROLES } from '@/config/roles';
import Modal from '@/components/Modal';
import LanguageSwitcher from '@/components/LanguageSwitcher';

export default {
  name: 'Navi',
  components: { Modal, LanguageSwitcher },
  props: {
    placement: {
      type: String,
      default: 'left',
    },
  },
  data() {
    return {
      width: 280,
      visible: false,
      adminOpened: false,
      simulationOpened: false,
      operationsOpened: false,
      reportOpened: false,
      rmsOpened: false,
      iscmOpened: false,
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
      displayName: 'displayName',
      hideSimulationPage: 'hideSimulationPage',
      isAdmin: 'isAdmin',
    }),
    adminMenuPermission() {
      return [
        'view-user',
        'modify-user',
        'view-partner',
        'add-partner',
        'modify-partner',
        'view-payment',
        'add-payment',
        'modify-payment',
        'view-system-log',
      ];
    },
    operationsMenuPermission() {
      return [
        'view-property',
        'add-property',
        'modify-property',
        'add-working-date',
        'modify-working-date',
        'view-room-assignment',
        'add-room-assignment',
        'modify-room-assignment',
        'view-inspection',
        'add-inspection',
        'modify-inspection',
        'view-hc-task',
        'modify-hc-task',
        'view-stock-control',
        'add-stock-control',
        'modify-stock-control',
        'view-lost-and-found',
        'add-lost-and-found',
        'modify-lost-and-found',
        'view-out-of-order',
        'add-out-of-order',
        'modify-out-of-order',
        'view-incident',
        'add-incident',
        'modify-incident',
        'view-reservation',
        'add-reservation',
        'modify-reservation',
        'view-guest-response',
        'add-guest-response',
        'modify-guest-response',
      ];
    },
    operationsMenuRoles() {
      return [
        ROLES.ADMIN,
        ROLES.OPERATION,
        ROLES.PROPERTY_OWNER,
        ROLES.HOUSEKEEPING_COMPANY,
        ROLES.HOUSEKEEPER,
        ROLES.CUSTOMER_SUPPORT,
      ];
    },
    rmsPermissions() {
      return [
        'view-rms',
        'add-rms',
        'modify-rms',
        'view-room-type',
        'add-room-type',
        'modify-room-type',
        'view-guest-email',
        'add-guest-email',
        'modify-guest-email',
        'view-rate',
        'add-rate',
        'modify-rate',
        'export-rate',
        'view-topline',
        'add-topline',
        'export-topline',
        'modify-topline',
        'view-topline-check',
        'add-topline-check',
        'modify-topline-check',
        'export-topline-check',
        'view-room-bottom',
        'add-room-bottom',
        'modify-room-bottom',
      ];
    },
    enableNeppanSimulator() {
      return process.env.VUE_APP_HIDE_NEPPAN_SIMULATOR !== 'true';
    },
  },
  watch: {
    $route() {
      // if the url change close it
      this.visible = false;
    },
  },
  methods: {
    showDrawer() {
      this.visible = true;
    },
    onClose() {
      this.visible = false;
    },
    logout() {
      logout();
    },
  },
};
</script>

<style lang="scss">
$drawerBg: #003945;
$menuActive: #12a3ac;
.navi-container {
  display: flex;
  height: 100%;
  padding: 10px 0 10px 30px;
  align-items: center;
  justify-content: center;
}

.navi-drawer {
  .ant-drawer-header {
    background-color: $drawerBg;
    border-radius: 0;
    border: none;
  }

  .ant-drawer-content {
    background-color: $drawerBg;
  }

  .ant-drawer-body {
    padding: 0;
  }

  .navi-menu {
    > li {
      > a {
        display: block;
        padding: 10px 30px;
        color: #ffffff;
        font-weight: bold;

        &.is-active {
          background-color: $menuActive;
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }

        img {
          margin-right: 15px;
        }
      }

      > span {
        display: block;
        position: relative;
        padding: 10px 30px;
        color: #ffffff;
        font-weight: bold;
        cursor: pointer;

        &:after {
          position: absolute;
          right: 30px;
          top: 15px;
          display: block;
          background: url('../assets/images/icon-arrow-down.svg');
          width: 6px;
          height: 8px;
          content: '';
          transition: all 0.2s ease-in-out;
        }

        &.isActive:after {
          transform: rotate(90deg);
        }

        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }

        strong {
          color: #ffffff;
        }

        img {
          margin-right: 15px;
        }

        &.language-switcher {
          padding: 0;

          &:after {
            display: none;
          }

          a {
            display: block;
            padding: 10px 30px;
            color: #ffffff;
            font-weight: bold;

            &.is-active {
              background-color: $menuActive;
            }

            &:hover {
              background-color: rgba(255, 255, 255, 0.2);
            }

            img {
              margin-right: 15px;
            }
          }
        }
      }

      > ul {
        > li {
          > a {
            display: block;
            padding: 10px 30px 10px 63px;
            color: #ffffff;
            font-weight: bold;
            background-color: rgba(255, 255, 255, 0.05);

            &.is-active {
              background-color: $menuActive;
            }

            &:hover {
              background-color: rgba(255, 255, 255, 0.2);
            }

            img {
              margin-right: 15px;
            }
          }
        }
      }
    }
  }
}
</style>
