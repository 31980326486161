<template>
  <div class="app-header">
    <nav aria-label="main navigation" class="navbar is-fixed-top" role="navigation">
      <div class="container is-max">
        <div class="navbar-brand">
          <Navi />
          <div class="logo navbar-item">
            <router-link class="navbar-item p-l-0" to="/">
              <Logo width="70" />
            </router-link>
          </div>
        </div>
        <div v-if="isAuthenticated" id="navbar" class="is-active navbar-menu">
          <div class="navbar-start" />
          <div class="navbar-end">
            <span class="has-text-weight-normal navbar-item navbar-item-border">{{
              displayName
            }}</span>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Logo from '@/components/Logo';
import Navi from '@/components/Navi';

export default {
  name: 'Header',
  components: {
    Navi,
    Logo,
  },
  computed: {
    ...mapGetters({
      isAuthenticated: 'isAuthenticated',
      displayName: 'displayName',
    }),
  },
};
</script>

<style lang="scss">
.app-header {
  box-shadow: 0 1px 0 0 #d0dbdc;
  overflow: hidden;
}

.navbar {
  border-bottom: 1px solid #dddddd;
}

.navbar-item.logo {
  padding: 0.2rem 0.8rem;
}

.navbar-item svg {
  margin-right: 4px;
  vertical-align: middle;
}

.navbar-dropdown a.navbar-item.is-active:after {
  display: none !important;
}

.navbar-item-border {
  border-left: 1px solid $border;
}

@media (max-width: $desktop - 1px) {
  .navbar-item-border {
    border-left: none;
  }
  .navbar-end > .navbar-item {
    border-bottom: 1px solid #ebebeb;

    &:last-child {
      border-bottom: none;
    }
  }
  .navbar-end .navbar-item {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .navbar-item.is-active {
    background-color: #ffffff !important;
  }
  .navbar-dropdown .navbar-item.is-active {
    background-color: transparent !important;
  }
  .navbar-end .navbar-dropdown {
    background: #f7f7f7;

    .navbar-item {
      padding-left: 3.2rem;
    }
  }
  .navbar-end > .has-dropdown {
    padding-top: 0;
    padding-bottom: 0;
    position: relative;

    > .navbar-item {
      margin-right: 4rem;
    }
  }
  .mobile-dropdown-handler {
    display: block;
    position: absolute;
    width: 4rem;
    top: 0;
    right: 0;
    height: 49px;
    content: '';
    background-size: 10px 6px;
    background: url('../assets/images/caret.svg') no-repeat center center;
    transform: rotate(180deg);
    transition: transform ease-in-out 0.2s;
  }
  .menu-open .mobile-dropdown-handler {
    transform: rotate(0deg);
  }
  .navbar-dropdown {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    overflow: hidden;
    max-height: 0;
    transition: max-height ease-in-out 0.2s;
  }
  .menu-open .navbar-dropdown {
    max-height: 200rem;
  }
}
</style>
